<template>
  <div>
    <GeneralDashboard @notify-parent="searchComplaints" />

    <div
      v-if="!loading"
      class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 3xl:grid-cols-8 md:gap-x-5 gap-1 justify-around"
    >
      <!-- Volumetría -->
      <ComplaintCardMini
        v-for="complaint in complaints"
        :key="complaint.title"
        :title="complaint.title"
        :numComplaints="complaint.numComplaints"
        :percent="complaint.percent"
        :danger="complaint.danger"
        :fall="complaint.fall"
      />
    </div>
    <div v-if="!loading" class="sm:grid favorability-section mb-3">
      <!-- Total de quejas por entidad -->
      <ComplaintTrendsBy
        title="Total de Quejas por Entidad"
        :favorableData="favorableDataEntity"
        :noFavorableData="noFavorableDataEntity"
        :parcialyFavorableData="parcialyFavorableDataEntity"
        :datax="dataFavorableEntity"
        horizontal
        class="complaints-entity"
      />
      <!-- Total de Quejas por Producto -->
      <ComplaintTrendsBy
        title="Total de Quejas por Producto"
        :favorableData="favorableDataProduct"
        :parcialyFavorableData="parcialyFavorableDataProduct"
        :noFavorableData="noFavorableDataProduct"
        :datax="dataFavorableProduct"
        class="complaints-product"
      />
      <!-- Inconformidades con intervenciones de entes de control -->
      <PieChart
        title="Inconformidades con intervenciones de entes de control"
        :data="pieDataEnte"
        :labels="labelsPieEnte"
        class="pie-section"
      />
      <!-- Total de Quejas por Fecha -->
      <ComplaintTrendsBy
        title="Total de Quejas por Fecha"
        :favorableData="favorableDataDate"
        :noFavorableData="noFavorableDataDate"
        :parcialyFavorableData="parcialyFavorableDataDate"
        :datax="dataFavorableDate"
        class="complaints-date"
      />
    </div>

    <div v-if="loading" class="flex justify-center items-center mt-32">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import GeneralDashboard from "@/components/Dashboard/Management/GeneralDashboard";
import ComplaintCardMini from "@/components/Dashboard/Management/General/ComplaintCardMini";
import ComplaintTrendsBy from "@/components/Dashboard/Management/Favorability/ComplaintTrendsBy";
import PieChart from "@/components/Dashboard/Management/General/PieChart.vue";
import moment from "moment";

const arrVolumetry = [
  {
    name: "Total de quejas",
    target: "total_codigo_queja",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Quejas con Tutela",
    target: "total_tutela",
    percent: "porcentaje_tutela",
  },
  {
    name: "Total Entidades",
    target: "total_nombre_entidad",
    percent: "porcentaje_nombre_entidad",
  },
  {
    name: "Total Usuarios",
    target: "total_numero_id_CF",
    percent: "porcentaje_numero_id_CF",
  },
  {
    name: "Total Ciudades",
    target: "total_codigo_pais",
    percent: "porcentaje_codigo_pais",
  },
  {
    name: "Tiempo total de respuesta",
    target: "sum_secconds",
    percent: "porcentaje_sum_secconds",
  },
  {
    name: "Cantidad de prorrogas",
    target: "total_sum_prorroga_queja",
    percent: "porcentaje_prorroga_queja",
  },
  {
    name: "Tiempo promedio de respuesta",
    target: "avg_secconds",
    percent: "porcentaje_avg_secconds",
  },
];

export default {
  components: {
    GeneralDashboard,
    ComplaintCardMini,
    ComplaintTrendsBy,
    PieChart,
  },
  data() {
    return {
      //Complaints card
      complaints: [],

      // Favorabilidad total entidades
      favorableDataEntity: [],
      noFavorableDataEntity: [],
      parcialyFavorableDataEntity: [],
      dataFavorableEntity: [],

      // Favorabilidad total productos
      favorableDataProduct: [],
      noFavorableDataProduct: [],
      parcialyFavorableDataProduct: [],
      dataFavorableProduct: [],

      // Ente de control
      pieDataEnte: [],
      labelsPieEnte: [],

      // Total quejas por fecha
      favorableDataDate: [],
      noFavorableDataDate: [],
      parcialyFavorableDataDate: [],
      dataFavorableDate: [],

      loading: false,
      actualQuery: {},
    };
  },
  created() {
    // Se carga por defecto los valores para una semana
    this.searchComplaints({
      filter_model: {
        fecha_creacion: {
          filter_type: "date",
          operator: "AND",
          condition1: {
            date_from: moment()
              .set(new Date())
              .subtract(7, "d")
              .format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "greaterThanOrEqual",
          },
          condition2: {
            date_from: moment().set(new Date()).format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "lessThanOrEqual",
          },
        },
      },
    });
  },
  methods: {
    searchComplaints(ev) {
      const promises = [];
      this.showToast("info", "Consultando gráficas...");
      this.loading = true;
      this.actualQuery = ev;
      // Construcción de todas las peticiones para la vista de reporte general
      // 1. Petición de volumetría
      const bodyVolumetry = {
        filter_model: this.actualQuery?.filter_model,
      };
      promises.push(this.$api.getVolumetry(bodyVolumetry));
      // 2. Total de quejas por entidad
      const bodyEntity = {
        filter_model: {
          ...this.actualQuery?.filter_model,
          a_favor_de: {
            filter_type: "set",
            values: ["Favorable", "Parcialmente favorable", "No favorable"],
          },
        },
        group_model: ["nombre_entidad", "a_favor_de"],
        limit: 20,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyEntity));
      // 3. Total de quejas por producto
      const bodyProduct = {
        filter_model: {
          ...this.actualQuery?.filter_model,
          a_favor_de: {
            filter_type: "set",
            values: ["Favorable", "Parcialmente favorable", "No favorable"],
          },
        },
        group_model: ["producto_nombre", "a_favor_de"],
        //limit: 10,
        //offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyProduct));
      // 4. Total de peticiones recurrentes
      const bodyRecurrent = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["tipo_identificacion_CF", "numero_id_CF", "a_favor_de"],
      };
      promises.push(this.$api.applyFilters(bodyRecurrent));
      // 5. Tendencias de las quejas por fecha
      const bodyDate = {
        filter_model: {
          ...this.actualQuery?.filter_model,
          a_favor_de: {
            filter_type: "set",
            values: ["Favorable", "Parcialmente favorable", "No favorable"],
          },
        },
        order_model: ["-DATE(q.fecha_creacion)"],
        group_model: ["DATE(q.fecha_creacion)", "a_favor_de"],
      };
      promises.push(this.$api.applyFilters(bodyDate));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          // console.log(response);

          // 1. Volumetría
          const volumetry = response[0]?.value?.data;
          this.complaints = [];
          let factor = 1;
          arrVolumetry.forEach((item) => {
            factor = item.target.includes("secconds") ? 3600 : 1;

            if (volumetry[item.percent] === null) {
              this.complaints.push({
                title: item.name,
                percent: 0,
                numComplaints: 0,
                danger: false,
                fall: false,
              });
            } else {
              this.complaints.push({
                title: item.name,
                percent: +(
                  volumetry[item.percent] < 0
                    ? -1 * volumetry[item.percent]
                    : volumetry[item.percent]
                ).toFixed(2),
                numComplaints: volumetry[item.target] / factor,
                danger: volumetry[item.percent] < 0,
                fall: volumetry[item.percent] < 0,
              });
            }
          });

          // 2. Total de quejas por entidad
          const companies = response[1]?.value?.data?.results;
          this.favorableDataEntity = [];
          this.noFavorableDataEntity = [];
          this.parcialyFavorableDataEntity = [];
          this.dataFavorableEntity = [];
          const groupByCompany = companies.reduce(function (acc, item) {
            let key = item["nombre_entidad"];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});
          // console.log(groupByCompany);

          // Se construyen los cuatro arreglos.
          const entities = Object.keys(groupByCompany);
          // console.log(entities);
          entities.forEach((item) => {
            // Nombre de la entidad
            this.dataFavorableEntity.push(item);
            // Se ingresan los valores de favorabilidad
            groupByCompany[item].forEach((el) => {
              switch (el.a_favor_de) {
                case "No favorable":
                  this.noFavorableDataEntity.push(el.total);
                  break;
                case "Parcialmente favorable":
                  this.parcialyFavorableDataEntity.push(el.total);
                  break;
                case "Favorable":
                  this.favorableDataEntity.push(el.total);
                  break;
              }
            });
          });

          // 3. Total de quejas por producto
          const product_list = response[2]?.value?.data?.results
            ?.filter((el) => el.a_favor_de)
            .filter((_, i) => i < 11);
          this.favorableDataProduct = [];
          this.noFavorableDataProduct = [];
          this.parcialyFavorableDataProduct = [];
          this.dataFavorableProduct = [];
          const groupByProduct = product_list.reduce(function (acc, item) {
            let key = item["producto_nombre"];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});
          // Se construyen los cuatro arreglos.
          const products = Object.keys(groupByProduct);
          products.forEach((item) => {
            // Nombre de la entidad
            this.dataFavorableProduct.push(item);
            // Se ingresan los valores de favorabilidad
            groupByProduct[item].forEach((el) => {
              switch (el.a_favor_de) {
                case "No favorable":
                  this.noFavorableDataProduct.push(el.total);
                  break;
                case "Parcialmente favorable":
                  this.parcialyFavorableDataProduct.push(el.total);
                  break;
                case "Favorable":
                  this.favorableDataProduct.push(el.total);
                  break;
              }
            });
          });

          // 4. Total de peticiones recurrentes
          const recurrency = response[3]?.value?.data;
          this.pieDataEnte = [0, 0, 0];
          this.labelsPieEnte = [
            "No favorable",
            "Parcialmente favorable",
            "Favorable",
          ];
          recurrency?.results.forEach((item, index) => {
            // Para el caso de más variables en juego
            /*const indexLabel = this.labelsPieEnte.findIndex(el => el === item.a_favor_de)
          if (indexLabel !== -1) {
            this.pieDataEnte[indexLabel] += item.total;
          } else {
            // Se suma a esa cantidad
            this.labelsPieEnte.push(item.a_favor_de);
            this.pieDataEnte.push(item.total);
          }*/
            // Para los tres casos
            switch (item.a_favor_de) {
              case "No favorable":
                this.pieDataEnte[0] += item.total;
                break;
              case "Parcialmente favorable":
                this.pieDataEnte[1] += item.total;
                break;
              case "Favorable":
                this.pieDataEnte[2] += item.total;
                break;
            }
          });

          // 5. Tendencias de las quejas por fecha
          let complaints_date = response[4]?.value?.data?.results;
          complaints_date = complaints_date.sort(function (a, b) {
            return new Date(a.f0_) - new Date(b.f0_);
          });
          this.favorableDataDate = [];
          this.noFavorableDataDate = [];
          this.parcialyFavorableDataDate = [];
          this.dataFavorableDate = [];
          const groupByDate = complaints_date.reduce(function (acc, item) {
            let key = item["f0_"];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});
          // Se construyen los cuatro arreglos.
          const dates = Object.keys(groupByDate);
          dates.forEach((item) => {
            // Nombre de la entidad
            this.dataFavorableDate.push(item);
            // Se ingresan los valores de favorabilidad
            groupByDate[item].forEach((el) => {
              switch (el.a_favor_de) {
                case "No favorable":
                  this.noFavorableDataDate.push(el.total);
                  break;
                case "Parcialmente favorable":
                  this.parcialyFavorableDataDate.push(el.total);
                  break;
                case "Favorable":
                  this.favorableDataDate.push(el.total);
                  break;
              }
            });
          });
          this.loading = false;
        })
        .catch((error) => console.error(error));
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style scoped>
.favorability-section {
  height: 200vh;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-areas:
    "complaints-by-entity complaints-by-entity complaints-by-product complaints-by-product complaints-by-product"
    "complaints-by-entity complaints-by-entity pie-section           pie-section           pie-section"
    "complaints-by-date   complaints-by-date   complaints-by-date    complaints-by-date    complaints-by-date";
  grid-gap: 1em;
}
.complaints-entity {
  grid-area: complaints-by-entity;
}
.complaints-product {
  grid-area: complaints-by-product;
}
.complaints-date {
  grid-area: complaints-by-date;
}
.pie-section {
  grid-area: pie-section;
}
@media only screen and (max-width: 1024px) {
  .favorability-section {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40em 40em 40em 40em;
    grid-template-areas:
      "complaints-by-entity "
      "complaints-by-product"
      "pie-section"
      "complaints-by-date";
    grid-gap: 1em;
  }
}
</style>